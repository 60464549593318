  .modal-footer {
      margin-left: 75%;
  }
  
  .modal-header {
      float: left;
      margin: 1% 10% !important;
  }
  
  .margin-top {
      margin-top: 1% !important;
  }
  
  .modal-backdrop {
      background-color: whitesmoke;
      max-width: 90%;
      margin-left: 5%;
      margin-top: 5%;
      outline: 0;
  }
  
  .header {
      background-color: #fff;
      position: sticky;
      top: 0;
      float: left;
  }
  
  .header-style {
      font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
      font-weight: 400;
      line-height: 1.5;
      color: grey;
      letter-spacing: 0.00938em;
  }
  
  .select-menu-left {
      display: flex;
  }
  
  div[class^="MuiLinearProgress-root-"] {
      display: none !important;
  }
  
  .carousel .slider.animated {
      height: 55vh;
  }
  
  .carousel .slide img {
      height: 55vh;
      object-fit: scale-down;
  }
  
  .carousel .thumbs-wrapper {
      margin: 10px 20px !important;
      overflow: hidden;
  }